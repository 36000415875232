<template>
  <div class="bt-transaksi pa-5">
    <h6
      class="text-h5 font-weight-bold orange--text text--lighten-1 d-flex align-center"
    >
      <div
        class="rounded-pill orange lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"
      >
        <v-icon small color="white">mdi-clipboard-list</v-icon>
      </div>
      Daftar Pengajuan
    </h6>
    <v-row class="mt-5">
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rembug</label>
        <v-select
          color="black"
          v-model="form.data.cm_code"
          :items="opt.rembug"
          @change="getPengajuanRembug();"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <!-- <v-col cols="12" class="py-0 mb-3">
        <label for="">Status</label>
        <v-select
          color="black"
          v-model="form.data.status"
          :items="opt.status"
          @change="setProfile();getPengajuanAnggota()"
          hide-details
          flat
          solo
          dense
        />
      </v-col> -->
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Anggota</label>
        <v-select
          color="black"
          v-model="form.data.cif_no"
          :items="opt.anggota"
          @change="setProfile();getPengajuanAnggota()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3" v-for="(p,pIndex) in opt.pengajuan" :key="pIndex">
        <v-card class="pa-3">
          <h6 class="text-h6 mb-1 font-weight-bold text-center">{{ p.registration_no }}</h6>
          <h6 class="text-h6 mb-1 font-weight-bold text-center">Rp {{thousand(p.amount)}}</h6>
          <p class="text-caption mb-2 text-center">
            {{ p.status }}
          </p>
          <v-row>
            <v-col cols="12">
              <router-link :to="`/pembiayaan/masuk/${p.registration_no}/${form.data.cif_no}/${form.data.cm_code}`">
                <v-btn block class="orange lighten-1 white--text rounded-lg" type="submit">
                  Lanjut Proses
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" class="py-0 mb-3" v-if="opt.pengajuan.length < 1">
        <v-card class="pa-3">
          <h6 class="text-h6 mb-1 font-weight-bold text-center" v-if="form.data.cif_no">
            {{ loading ? 'Memuat data...' : 'Anggota tidak memiliki pengajuan yang belum di proses.' }}
          </h6>
          <h6 class="text-h6 mb-1 font-weight-bold text-center" v-else>
            Silahkan pilih rembug dan anggota
          </h6>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="alert.show" :timeout="3000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper";
export default {
  name: "KeangotaanKeluar",
  components: {
  },
  data() {
    return {
      profile: Object,
      form: {
        data: {
          cm_code: null,
          cif_no: null,
          status: "Registrasi"
        }
      },
      opt: {
        rembug: [],
        anggota: [],
        pengajuan: [],
        status: ["Aktivasi","Registrasi"]
      },
      alert: {
        show: false,
        msg: null,
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters(["user","pembiayaan"]),
  },
  methods: {
    ...helper,
    async getRembug() {
      let day = new Date().getDay();
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("day", day);
      // payload.append("day", 1);
      try {
        let req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.rembug = [];
          if(data){
            data.map((item) => {
              let opt = {
                value: item.cm_code,
                text: item.cm_name,
                data: item,
              };
              this.opt.rembug.push(opt);
            });
          }
        } else {
          this.notif('Gagal mendapatkan data Rembug')
        }
      } catch (error) {
        this.notif(error)
      }
    },
    async getAnggota() {
      let payload = new FormData();
      payload.append("cm_code", this.form.data.cm_code);
      try {
        let req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.anggota = [];
          data.map((item) => {
            let opt = {
              value: item.cif_no,
              text: item.nama,
              data: item,
            };
            this.opt.anggota.push(opt);
          });
        } else {
          this.notif('Gagal mendapatkan data Anggota')
        }
      } catch (error) {
        this.notif(error)
      }
    },
    async getPengajuanAnggota() {
      this.opt.pengajuan = [];
      let payload = new FormData();
      payload.append("cif_no", this.form.data.cif_no);
      if(this.form.data.cif_no){
        this.loading = true
        try {
          let req = await services.pengajuanAnggota(payload, this.user.token);
          let { data, status, msg } = req.data;
          if (req.status === 200 && status) {
            data.map((item) => {
              if(item.status == this.form.data.status) {
                this.opt.pengajuan.push(item)
              }
            })
          } else {
            this.alert = {
              show: true,
              msg: 'Gagal mendapatkan data pengajuan Anggota',
            };
          }
          this.loading = false
        } catch (error) {
          this.alert = {
            show: true,
            msg: error,
          };
          this.loading = false
        }
      } else {

      }
    },
    async getPengajuanRembug() {
      let payload = new FormData();
      this.form.data.cif_no = null
      this.opt.pengajuan = []
      this.opt.anggota = []
      payload.append("cm_code", this.form.data.cm_code);
      try {
        let req = await services.pengajuanRembug(payload, this.user.token);
        if (req.status === 200) {
          let { data, status, msg } = req.data;
          if(status){
            this.opt.anggota = [];
            data.map((item) => {
              let opt = {
                value: item.cif_no,
                text: item.nama,
                data: item,
              };
              this.opt.anggota.push(opt);
            });
          } else {
            this.alert = {
              show: true,
              msg: msg,
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan data Anggota',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    getPembiayaan() {
      this.opt.pengajuan = []
      this.pembiayaan.map((item) => {
        if(item.cif_no == this.form.data.cif_no){
          this.opt.pengajuan.push(item)
        }
      })
    },
    setProfile() {
      let profile = this.opt.anggota.find(
        (item) => item.value == this.form.data.cif_no
      ).data;
      this.profile = profile
    },
    generateDate(){
      var dateObj = new Date();
      var month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
      var day = String(dateObj.getUTCDate()).padStart(2, '0')
      var year = dateObj.getUTCFullYear();
      var hours = dateObj.getHours()
      var minutes = dateObj.getMinutes()
      var seconds = dateObj.getSeconds()
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    notif(msg) {
      this.alert = {
        show: true,
        msg: msg,
      };
    },
  },
  mounted() {
    this.getRembug();
  },
};
</script>
